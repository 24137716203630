var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"550","persistent":_vm.loading},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","tile":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('project.header.upload.multipleFiles'))+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2",staticStyle:{"color":"#183857"}},[_vm._v(" "+_vm._s(_vm.$t('project.header.upload.chooseFiles'))+" ")]),_c('v-card-text',[_c('v-file-input',{attrs:{"multiple":""},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}),_c('v-tabs',{attrs:{"background-color":"transparent","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" "+_vm._s(_vm.$t('allFiles'))+" ")]),_c('v-tab',[_vm._v(" "+_vm._s(_vm.$t('errors'))+" ")]),_c('v-tab',[_vm._v(" "+_vm._s(_vm.$t('ignored'))+" ")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((3),function(i){return _c('v-tab-item',{key:i},[_c('v-virtual-scroll',{attrs:{"height":"400","items":_vm.filesList(i),"item-height":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{key:item.file.name,ref:item.file.size,refInFor:true,attrs:{"color":(item.loaded || item.error) ? (item.loaded ? 'success' : (item.error ? 'error' : '')): ''}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.file.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(((item.file.size / 1000) + " kB"))+" ")])],1),_c('v-list-item-action',[(item.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(item.error)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v(_vm._s('mdi-upload-off'))]):_vm._e(),(item.loaded)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(_vm._s('mdi-check'))]):_vm._e(),(item.exists)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v(_vm._s('mdi-alert-circle')+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('ignored')))])]):_vm._e()],1)],1)]}}],null,true)})],1)}),1),_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"absolute":"","bottom":"","color":"primary"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"tile":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('project.header.upload.duplicateOptions'))+" ")])]}}]),model:{value:(_vm.options),callback:function ($$v) {_vm.options=$$v},expression:"options"}},[_c('v-card',{staticClass:"pl-3",attrs:{"elevation":"0"}},[_c('v-radio-group',{model:{value:(_vm.strategy),callback:function ($$v) {_vm.strategy=$$v},expression:"strategy"}},[_c('v-radio',{attrs:{"label":_vm.$t('ignore'),"value":'IGNORE'}}),_c('v-radio',{attrs:{"label":_vm.$t('copy'),"value":'COPY'}}),_c('v-radio',{attrs:{"label":_vm.$t('rewrite'),"value":'REWRITE'}})],1)],1)],1),_c('v-spacer'),_c('v-btn',{attrs:{"tile":"","disabled":_vm.loading,"color":"error"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")]),_c('v-btn',{attrs:{"tile":"","disabled":!_vm.files.length || _vm.disable,"color":"success"},on:{"click":_vm.upload}},[_vm._v(" "+_vm._s(_vm.$t('project.header.upload.title'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }