<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        width="550"
        :persistent="loading"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            tile
            v-bind="attrs"
            v-on="on"
        >
          {{ $t('project.header.upload.multipleFiles') }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2" style="color: #183857">
          {{ $t('project.header.upload.chooseFiles') }}
        </v-card-title>

        <v-card-text>
          <v-file-input v-model="files" multiple></v-file-input>
          <v-tabs
              v-model="tab"
              background-color="transparent"
              grow
          >
            <v-tab>
              {{ $t('allFiles') }}
            </v-tab>

            <v-tab>
              {{ $t('errors') }}
            </v-tab>

            <v-tab>
              {{ $t('ignored') }}
            </v-tab>
          </v-tabs>


          <v-tabs-items v-model="tab">
            <v-tab-item v-for="i in 3" :key="i">
              <v-virtual-scroll height="400" :items="filesList(i)" item-height="50">
                <template v-slot:default="{item}">
                  <v-list-item :key="item.file.name" :ref="item.file.size"
                               :color="(item.loaded || item.error) ? (item.loaded ? 'success' : (item.error ? 'error' : '')): ''">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.file.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ `${item.file.size / 1000} kB` }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-progress-circular
                          v-if="item.loading"
                          indeterminate
                          color="primary"
                      ></v-progress-circular>
                      <v-icon v-if="item.error" color="error">{{ 'mdi-upload-off' }}</v-icon>
                      <v-icon v-if="item.loaded" color="success">{{ 'mdi-check' }}</v-icon>

                      <v-tooltip left v-if="item.exists">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs"
                                  color="warning"
                                  v-on="on">{{ 'mdi-alert-circle' }}
                          </v-icon>
                        </template>
                        <span>{{$t('ignored')}}</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-tab-item>
          </v-tabs-items>
          <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              absolute
              bottom
              color="primary"
          ></v-progress-linear>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-menu
              v-model="options"
              :close-on-content-click="false"
              offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn tile
                     v-bind="attrs"
                     v-on="on"  >
                {{ $t('project.header.upload.duplicateOptions')}}
              </v-btn>
            </template>
            <v-card class="pl-3" elevation="0">

              <v-radio-group v-model="strategy">
                <v-radio
                    :label="$t('ignore')"
                    :value="'IGNORE'"
                ></v-radio>
                <v-radio
                    :label="$t('copy')"
                    :value="'COPY'"
                ></v-radio>
                <v-radio
                    :label="$t('rewrite')"
                    :value="'REWRITE'"
                ></v-radio>
              </v-radio-group>
            </v-card>
          </v-menu>
          <v-spacer></v-spacer>
          <v-btn
              tile
              :disabled="loading"
              color="error"
              @click="cancel"
          >
            {{ $t('close') }}
          </v-btn>
          <v-btn
              tile
              :disabled="!files.length || disable"
              color="success"
              @click="upload"
          >
            {{ $t('project.header.upload.title') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {Upload} from '@progress/kendo-upload-vue-wrapper'

export default {
  name: 'BulkUploadPopUp',
  props: ['id', 'closeDialog'],
  data() {
    return {
      dialog: false,
      loading: false,
      files: [],
      options: false,
      strategy: 'IGNORE',
      disable: false,
      tab: 0
    }
  },
  components: {
    Upload
  },
  methods: {
    ...mapActions({
      isFileExist: 'main/isFileExist',
      uploading: 'main/upload',
      configParse: 'main/configParse',
      parseFile: 'main/parseFileMetadata'
    }),
    ...mapMutations({
      setFile: 'main/setUploadFile',
      setLoading: 'main/setUploadedFileLoading',
      setLoaded: 'main/setUploadedFileLoaded',
      setError: 'main/setUploadedFileError',
      setExists: 'main/setUploadedFileExists',
      setUploadStrategy: 'main/setUploadStrategy'
    }),
    upload() {
      this.setFile(this.files)
      this.disable = true
      this.files.forEach((file, index) => {
        this.setUploadStrategy('')
        let formData = new FormData()
        formData.append('file', file)
        formData.append('projectId', this.id)
        this.setLoading(index)
        const fileCheck = new FormData();
        fileCheck.append('file', file)
        this.isFileExist({file: fileCheck,  projectId: this.id}).then(isExist => {
          if (isExist) {
            if(this.strategy !== 'IGNORE'){
              this.setUploadStrategy(this.strategy)
            } else {
              this.setExists(index)
              this.setLoading(index)
              this.disable = false
            }
          } else {
            this.setUploadStrategy('')
          }
          this.uploading({formData, uploading: true, goBack: false}).then(res => {
            this.setLoaded(index)
            this.setLoading(index)
            this.disable = false
          }).catch(err => {
            this.setLoading(index)
            this.setError(index)
            this.disable = false
          })
        })
      })
    },
    cancel() {
      if (this.closeDialog) {
        this.closeDialog()
      }
      this.dialog = false
    },
  },
  watch: {
    files(value) {
      this.setFile(value)
    },
    dialog(value) {
      this.files=[]
    }
  },
  computed: {
    ...mapGetters({
      getFiles: 'main/getUploadingFiles'
    }),
    filesList() {
      return index => {
        switch (index) {
          case 1:
            return this.getFiles
          case 2:
            return this.getFiles.filter(file => file.error)
          case 3:
            return this.getFiles.filter(file => file.exists)
        }
      }
    }
  }
}
</script>