<template>
  <v-container fluid class="ma-0">
    <app-header>
      <column-menu></column-menu>

      <upload-menu :id="id"></upload-menu>

      <download-menu :selected="true"></download-menu>

      <ChannelPopUp></ChannelPopUp>
      <download-zip-menu :projectId="id"></download-zip-menu>

      <v-btn text v-if="selected.length > 1" @click="deleting = true">
        <v-icon color="primary" class="pr-1">
          {{ $router.currentRoute.name === 'Deleted' ? 'mdi-delete-restore' : 'mdi-delete' }}
        </v-icon>
        {{
          (selected.length && selected[0].info.deleted) ? $t('project.header.restoreFiles') : $t('project.header.removeFiles')
        }}
      </v-btn>

      <v-btn text v-if="selected.length > 1  && $router.currentRoute.name !== 'Deleted'" @click="archiving = true">
        <v-icon class="pr-1">
          {{ $router.currentRoute.name === 'Archived' ? 'mdi-archive-arrow-up' : 'mdi-archive' }}
        </v-icon>
        {{ $t('project.header.archiveFiles') }}
      </v-btn>

      <v-btn text v-if="selected.length > 1 && $router.currentRoute.name === 'Deleted'"
             @click="permDelete = true">
        <v-icon color="primary" class="pr-1">
          {{ 'mdi-delete' }}
        </v-icon>
        {{
          $t('project.header.removeFiles')
        }}
      </v-btn>

      <v-snackbar
          v-model="deleting"
      >
        {{ (selected.length && selected[0].info.deleted) ? $tc('restoring.question', 2) : $tc('deleting.question', 2) }}

        <template v-slot:action="{ attrs }">
          <v-btn
              color="error"
              text
              v-bind="attrs"
              @click="deleting = false"
          >
            {{ $t('no') }}
          </v-btn>
          <v-btn
              color="success"
              text
              v-bind="attrs"
              @click="deleteFiles"
          >
            {{ $t('yes') }}
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
          v-model="archiving"
      >
        {{
          (selected.length && selected[0].info.archived) ? $tc('restoring.question', 2) : $tc('archiving.question', 2)
        }}

        <template v-slot:action="{ attrs }">
          <v-btn
              color="error"
              text
              v-bind="attrs"
              @click="archiving = false"
          >
            {{ $t('no') }}
          </v-btn>
          <v-btn
              color="success"
              text
              v-bind="attrs"
              @click="archiveFiles"
          >
            {{ $t('yes') }}
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
          v-model="permDelete"
      >
        {{ $tc('deleting.question', 2) }}

        <template v-slot:action="{ attrs }">
          <v-btn
              color="error"
              text
              v-bind="attrs"
              @click="permDelete = false"
          >
            {{ $t('no') }}
          </v-btn>
          <v-btn
              color="success"
              text
              v-bind="attrs"
              @click="permanentDeleteFiles"
          >
            {{ $t('yes') }}
          </v-btn>
        </template>
      </v-snackbar>

      <v-btn text v-if="selected.length > 1 && $router.currentRoute.name!=='Deleted'" @click="syncItems">
        <v-icon class="pr-1">
          {{ 'mdi-sync' }}
        </v-icon>
        {{ $t('project.header.sync') }}
      </v-btn>
    </app-header>

    <!--
    <v-overlay v-if="isLoading"
               opacity="0.75"
               style="backdrop-filter: blur(6px);"
    >
      <v-progress-circular
          style="position: absolute"
          :size="50"
          indeterminate>
      </v-progress-circular>
    </v-overlay>
    -->


    <v-row class="fill-height">
      <v-col sm="12" md="2" v-if="drawer" class="pl-0" style="background-color: #eeeeee">
        <v-navigation-drawer width="100%" stateless permanent
                             v-if="drawer"
        >
          <v-list>
            <v-subheader>{{ $t('project.folders.folders') }}</v-subheader>
            <v-list-item-group
                mandatory
                v-model="selectedFolder"
                color="primary"
            >
              <v-list-item
                  @click="requestUpdate('', i)"
                  v-for="(folder, i) in folders"
                  :key="i"
              >
                <v-list-item-icon>
                  <v-icon v-text="'mdi-folder'"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="folder.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-col>
    </v-row>

    <!-- FILE LISTING -->
    <v-row>
      <v-col>
        <v-container fluid>
          <v-row>
            <template>
              <v-icon color="primary">
                {{ 'mdi-chevron-left' }}
              </v-icon>
              <h4>{{ $tc(`routesNames.${this.$router.currentRoute.name.toLowerCase()}`) }}</h4>
            </template>
          </v-row>

          <v-row justify="center" style="background-color: white; margin-top: 10px">
            <v-col>
              <file-listing :id="id" :selected="selected"></file-listing>
            </v-col>
          </v-row>
        </v-container>
        <!--<router-view v-bind:id="id"/>-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ColumnMenu from "@/components/ColumnMenu";
import AppHeader from "@/components/AppHeader";
import UploadPopUp from "@/components/UploadPopUp";
import ChannelPopUp from "@/components/ChannelPopUp";
import {mapActions, mapGetters, mapMutations} from "vuex";
import DownloadMenu from "@/components/DownloadMenu";
import DownloadZipMenu from "@/components/DownloadZipMenu";
import UploadMenu from "@/components/UploadMenu";
import FileListing from "@/views/FileListing";

export default {
  name: "FilesOverview",
  props: ['filter', 'id'],
  components: {
    FileListing,
    UploadMenu,
    DownloadZipMenu: DownloadZipMenu,
    DownloadMenu,
    AppHeader,
    ColumnMenu,
    UploadPopUp,
    ChannelPopUp,
  },
  data() {
    return {
      drawerSize: 2,
      filesSize: 10,
      drawer: true,
      selectedFolder: 0,
      deleting: false,
      permDelete: false,
      archiving: false
    }
  },
  mounted() {
    this.updateDrawer();
  },
  computed: {
    ...mapGetters({
      getFiles: 'main/getFiles',
      getCurrentRequest: 'main/getCurrentRequest',
      selected: 'main/getSelected',
      isLoading: 'main/isLoading'
    }),
    folders() {
      return [
        {text: this.$t('project.folders.allFiles'), filter: {folder: null}},
        {text: this.$t('project.folders.recentlyAdded'), filter: {folder: 'recent'}}
      ]
    }
  },
  methods: {
    ...mapActions({
      updateFiles: 'main/getFiles',
      deleteFile: 'main/deleteFile',
      permanentDelete: 'main/permanentDeleteFile',
      addToArchive: 'main/addToArchive',
      restoreFromArchive: 'main/restoreFromArchive',
      restoreFile: 'main/restoreFile',
      sync: 'main/sync'
    }),
    ...mapMutations({
      setCurrentRequest: 'main/setCurrentRequest',
      setSelected: 'main/setSelected',
      loading: 'main/loading',
      addSyncing: 'main/addSyncing'
    }),
    updateDrawer() {
      this.drawer = false //this.$router.currentRoute.name === 'CurrentProject'
      if (this.drawer) {
        this.filesSize = 10
        this.drawerSize = 2
      } else {
        this.filesSize = 12
        this.drawerSize = 12
      }
    },
    syncItems() {
      this.selected.forEach(item => {
        this.addSyncing(item.id)
        this.sync(item.id)
      })
    },
    setRouteName(name) {
      this.routeName = name
    },
    changeFolder(folder) {
      const current = this.getCurrentRequest
      current.filter = {...current.filter, ...folder.filter}
      this.setCurrentRequest(current)
    },
    deleteFiles() {
      this.deleting = false
      this.selected.forEach(file => {
        if (file.info.deleted) {
          this.restoreFile(file.id)
        } else {
          this.deleteFile(file.id)
        }
      })
      this.setSelected([])
    },
    permanentDeleteFiles() {
      this.deleting = false
      this.selected.forEach(file => {
        this.permanentDelete(file.id)
      })
      this.setSelected([])
    },
    archiveFiles() {
      this.archiving = false
      this.selected.forEach(file => {
        if (file.info.archived) {
          this.restoreFromArchive(file.id)
        } else {
          this.addToArchive(file.id)
        }
      })
      this.setSelected([])
    },
    requestUpdate(value, index) {
      console.log("request");
      let current = this.getCurrentRequest
      current.page = 0
      current.sortList = []
      if (value === 'Deleted') {
        this.changeFolder(this.folders[0])
        current.filter = {
          ...current.filter,
          'info.projectId': this.id,
          'info.marked': null,
          'info.archived': null,
          'info.deleted': true,
          'info.selected': null
        }
        this.setCurrentRequest(current)
        this.updateFiles(true)
      } else if (value === 'Archived') {
        this.changeFolder(this.folders[0])
        current.filter = {
          ...current.filter,
          'info.projectId': this.id,
          'info.marked': null,
          'info.archived': true,
          'info.deleted': false,
          'info.selected': null
        }
        this.setCurrentRequest(current)
        this.updateFiles(true)
      } else if (value === 'Marked') {
        this.changeFolder(this.folders[0])
        current.filter = {
          ...current.filter,
          'info.projectId': this.id,
          'info.marked': true,
          'info.archived': false,
          'info.deleted': false,
          'info.selected': null
        }
        this.setCurrentRequest(current)
        this.updateFiles(true)
      } else if (value === '' || 'CurrentProject') {
        current.filter = {
          ...current.filter,
          'info.projectId': this.id,
          'info.marked': null,
          'info.archived': false,
          'info.deleted': false,
          'info.selected': null
        }
        this.changeFolder(this.folders[index || 0])
        this.updateFiles(true)
      }
    }
  }
}
</script>

<style scoped>

</style>