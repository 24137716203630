<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        width="700"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            tile
            text
            v-bind="attrs"
            v-on="on"
        >
          <v-icon class="pr-1">
            {{ 'mdi-share-variant' }}
          </v-icon>
          {{ $t('project.header.channels.title') }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline lighten-2"
                      style="color: #183857;">
          {{ $t('project.header.channels.assign') }}
        </v-card-title>
        <v-card v-for="channel in getChannels" elevation="0" :key="channel.id">
          <v-container class="channels-form">
            <v-row dense>
              <v-col>
                <v-card elevation="0">
                  <v-card-text>
                    {{
                      (channel.type) ? `${channel.type}: ${channel.url}` : `${channel.labels[getLocale] || channel.labels['en-EN']}`
                    }}
                    <a>(#{{ channel.systemId }})</a>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col>
                <v-btn class="pt-2 pb-2 ma-2" tile color="error" @click="updateChannel(channel, 'remove')">
                  <v-icon> {{ 'mdi-close' }}</v-icon>
                </v-btn>
                <v-btn class="pt-2 pb-2 ma-2" tile color="success" @click="updateChannel(channel, 'add')">
                  <v-icon> {{ 'mdi-plus' }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              tile
              color="error"
              @click="dialog = false"
          >
            <v-icon>{{ 'mdi-close' }}</v-icon>
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
              tile
              color="success"
              @click="dialog = false"
              :disabled="!getSelected.length"
          >
            <v-icon>{{ 'mdi-check' }}</v-icon>
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: 'ChannelPopUp',
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    ...mapActions({
      upload: 'main/upload',
      addChannel: 'main/addChannel',
      removeChannel: 'main/removeChannel'
    }),
    //channels update with 'add' and 'remove' for each button
    updateChannel(channel, mode) {
      const selected = this.getSelected
      selected.map(file => {
            if (file.info.channels) {
              switch (mode) {
                case 'add': {
                  if (!file.info.channels.find(chn => chn === channel.systemId)) {
                    file.info.channels.push(channel.systemId)
                    this.addChannel({mediaFileId:file.id, channelId: channel.systemId})
                  } else {
                    file.info.channels = [channel.systemId]
                    this.addChannel({mediaFileId:file.id, channelId: channel.systemId})
                  }
                } break;
                case 'remove':{
                  if (file.info.channels.find(chn => chn === channel.systemId)) {
                    file.info.channels = file.info.channels.filter(id => {
                      if (channel.systemId === id) {
                        this.removeChannel({mediaFileId: file.id, channelId: channel.systemId})
                        return false
                      } else return true
                    })
                  }
                } break;
              }
            }
          }
      )
    }
  },
  computed: {
    ...mapGetters({
      getSelected: 'main/getSelected',
      getChannels: 'main/getChannels',
      getLocale: 'main/getLocale'
    }),
  }
}
</script>

<style lang="sass">
.channels-form
  text-align: left
</style>