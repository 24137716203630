<template>
  <div>
    <v-container>
      <!-- FILTER -->
      <file-filter v-if="$router.currentRoute.name !== 'Selected'" :id="id"></file-filter>

      <!-- FILES -->
      <v-row justify="center" align="center">
        <file-table :key="$router.currentRoute.name" :id="id"/>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SaveFilterPopUp from "@/components/SaveFilterPopUp";
import FileTable from "@/components/files/FileTable";
import FileFilter from "@/components/files/FileFilter";

export default {
  name: 'FileListing',
  components: {FileFilter, FileTable, SaveFilterPopUp},
  props: [
    'id',
    'selected'
  ],
  data() {
    return {}
  }
}
</script>

<style lang="sass">
.content-grid
  text-align: center
  align-content: center

.files-cards
  max-height: 150px
</style>