<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        width="550"
        :persistent="loading"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            tile
            v-bind="attrs"
            v-on="on"
        >
          {{ $t('project.header.upload.file') }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2" style="color: #183857">
          {{ $t('project.header.upload.chooseImage') }}
        </v-card-title>

        <v-card-text>
          <v-file-input
              :rules="rules"
              :placeholder="$t('project.header.upload.pickAnImage')"
              :label="$t('project.header.upload.yourImage')"
              v-model="file">
          </v-file-input>
          <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              absolute
              bottom
              color="primary"
          ></v-progress-linear>
        </v-card-text>


        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              tile
              :disabled="loading"
              color="error"
              @click="cancel"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
              :disabled="!file || loading"
              color="success"
              @click="fileCheck"
          >
            {{ $t('project.header.upload.title') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
    v-model="exist"
    max-width="800"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2" style="color: #183857">
          {{ $t('upload.fileAlreadyExists') }}
        </v-card-title>
        <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            bottom
            color="primary"
        ></v-progress-linear>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              tile
              :disabled="loading"
              color="error"
              @click="cancel"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
              :disabled="!file || loading"
              color="success"
              @click="strategy('COPY')"
          >
            {{ $t('project.header.upload.createCopy') }}
          </v-btn>
          <v-btn
              :disabled="!file || loading"
              color="success"
              @click="strategy('REWRITE')"
          >
            {{ $t('project.header.upload.rewriteFile') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: 'UploadPopUp',
  props: ['id', 'closeDialog'],
  data() {
    return {
      rules: [
        value => !value || value.size < 100000000 || 'Image size should be less than 100 MB!',
      ],
      dialog: false,
      exist: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      getFile: 'main/getFileUpload',
      getConfig: 'main/getConfig',
      getDocumentConfig: 'main/getDocumentConfig'
    }),
    file: {
      get() {
        return this.getFile
      },
      set(value) {
        this.setFileUpload(value)
        if (value) {
          this.setPreview(this.createObjectURL(value))
        }
      }
    }
  },
  methods: {
    ...mapActions({
      isFileExist: 'main/isFileExist',
      getMetadata: 'main/getMetadata',
      upload: 'main/upload',
      configParse: 'main/configParse',
      parseFile: 'main/parseFileMetadata'
    }),
    ...mapMutations({
      setPreview: 'main/setPreview',
      setFileUpload: 'main/setFileUpload',
      setUploadStrategy: 'main/setUploadStrategy',
      setEditMetadata: 'main/setEditMetadata'
    }),
    async uploadAndParse() {
      this.loading = true
      let data = new FormData()
      data.append('file', this.file)
      data.append('projectId', this.id)
      let file = await this.upload({formData: data, uploading: true, goBack: false})
          .catch(err => {
            this.loading = false
            this.file = undefined
            if (this.closeDialog) this.closeDialog()
            this.dialog = false
            this.exist = false
          }).catch();

      if (!file)
        return;

      /*
      if (file.info.type === 'IMAGE') {
        await this.configParse(this.getConfig.form)
        this.imageData = await JSON.parse(await JSON.stringify(this.getConfig))
      } else {
        await this.configParse(this.getConfig.form)
        this.imageData = await JSON.parse(await JSON.stringify(this.getConfig))
      }

      this.imageData.id = file.id
      this.imageData.info = file.info
      await this.parseFile({data: file.metadata, imageData: this.imageData})
      this.setEditMetadata(this.imageData)
       */

      this.loading = false;
      await this.$router.push(`/projects/${this.id}/edit/${file.id}`);
    },

    async fileCheck() {
      const formData = new FormData();
      formData.append('file', this.file)
      const isExist = await this.isFileExist({file: formData, projectId: this.id})
      if (isExist) {
        this.exist = true
        this.dialog = false
      } else {
        await this.uploadAndParse()
      }
    },
    async strategy(str) {
      this.loading = true
      this.setUploadStrategy(str)
      await this.uploadAndParse().then(() => {
            this.loading = false
            this.exist = false
            this.dialog = false
            if (this.closeDialog) this.closeDialog()
          }
      )
    },
    cancel() {
      this.dialog = false
      if (this.closeDialog) {
        this.closeDialog()
      }
      this.exist = false
      this.file = undefined

    }
  }
}
</script>