<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        width="350"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            text
            tile
            v-bind="attrs"
            v-on="on"
        >
          <v-icon class="pr-1">
            {{ 'mdi-file-upload-outline' }}
          </v-icon>
          {{ $t('project.header.upload.title') }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2 justify-center" style="color: #183857">
          {{ $t('project.header.upload.chooseType') }}
        </v-card-title>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <UploadPopUp :id="id" :closeDialog="close"></UploadPopUp>
          <v-spacer></v-spacer>
        <BulkUploadPopUp :id="id" :closeDialog="close"></BulkUploadPopUp>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import UploadPopUp from "@/components/UploadPopUp";
import BulkUploadPopUp from "@/components/BulkUploadPopUp";

export default {
  name: 'UploadMenu',
  props: ['id'],
  data() {
    return {
      dialog: false,
    }
  },
  components: {
    UploadPopUp,
    BulkUploadPopUp
  },
  methods: {
    close() {
      this.dialog = false
    }
  },

}
</script>